@import 'src/utils/color.scss';
* {
    margin:0;
    padding: 0;
    list-style: none;
}
html {
    scroll-behavior: smooth;
}
body {
    font-family: 'Raleway', sans-serif;
    font-size: 18px;
    font-weight: 400;
    position:relative;
}
.container {
    max-width:1200px;
    margin:0 auto;
    padding: 30px;
    width: fit-content;
    overflow: hidden;
}
img {
    max-width: 100%;
    height: auto;
}
h2.title {
    font-size: 2.5em;
    padding-bottom: .5em;
    margin-bottom: 1.5em;
    position: relative;
    &::after{
        content: "";
        background-color: $primary;
        display: block;
        height: 5px;
        width: 150px;
        position: absolute;
        bottom:0px;
        left:-15px;
    }
}
@media (max-width: 780px) {
    body {
        font-size:16px
    }
}
@media (max-width: 420px) {
    body {
        font-size:13px
    }
}