@import 'src/utils/color.scss';
.filterCat {
    display: flex;
    justify-content: center;
    gap:1em;
    flex-wrap: wrap;
    li {
        cursor: pointer;
        color:white;
        background-color: $primary;
        padding: 5px 10px;
        font-size: 1.2em;
        transition: all .5s ease-in-out;
        border:solid 1px $primary;
        &:hover, &.active {
         background-color: white;
         color:$primary;
        }
    }
}
.my-masonry-grid {
    display: -webkit-box; /* Not needed if autoprefixing */
    display: -ms-flexbox; /* Not needed if autoprefixing */
    display: flex;
    margin-left: -10px; /* gutter size offset */
    width: auto;
    margin-top:50px;
  }
  .my-masonry-grid_column {
    padding-left: 10px; /* gutter size */
    background-clip: padding-box;
  }
  
  /* Style your items */
  .my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
    margin-bottom: 10px;
  }
  @keyframes fadeIn {
    from {
        opacity:0
    }
    to {
        opacity:1
    }
  }
  #work .item {
    position: relative;
    overflow: hidden;
    cursor: pointer;
    animation: fadeIn 1s;
    box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.1);
    img {
        transition: all .5s ease-in-out;
        width:100%;
        margin-bottom: -5px;
        scale: 1;
        &:hover {
            scale: 1.3;
        }
    }
    .over {
        opacity: 0;
        background-color: rgba($color: #06948e, $alpha: 0.8);
        color:white;
        text-align: center;
        position: absolute;
        bottom: -400px;
        transition: all .5s ease-in-out;
        width:calc(100% - 60px);
        height:0;
        padding: 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    h3 {
        margin-bottom:10px;
    }
    &:hover {
        .over{
            bottom: 0;
            opacity: 1;
            padding: 30px;
            height: calc(100% - 60px);
        }
    }
  }
.ReactModal__Overlay.ReactModal__Overlay--after-open {
    background-color: rgba(0,0,0,0.8) !important;
    z-index: 9999;
}
.ReactModal__Content.ReactModal__Content--after-open {
    max-width:800px;
    width:auto;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.3);
    max-height: 90vh;
}
button.close-modal {
    border: none;
    color: white;
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 2.5em;
    border-radius: 100%;
    background: #06948e;
    width: 1em;
    height: 1em;
    transition: all .5s ease-in-out;
    padding: 0;
    cursor:pointer;
    z-index: 99;
    &:hover {
        background: red;  
    }
}
.modal-work {
    font-size:0.9em;
    h2, h3, p {
        padding-top: 0.5em
    }
    .tag {
        display:flex;
        flex-wrap: wrap;
        gap:10px;
        margin-top:1em;
        li {
            border-radius: 5px;
            background-color: #efefef;
            padding:5px 10px;
        }
    }
    .btn {
        display:flex;
        gap:1em;
        justify-content: center;
        font-size: 1.1em;
        padding-top: 1em;
        a {
            padding: 5px 15px;
            background-color: $primary;
            color:white;
            transition:all .5s ease-in-out;
            text-decoration: none;
            border: solid 1px $primary;
            &:hover {
                background-color: white;
                color: $primary;
            }
            &:last-child {
                background-color: white;
                color: $primary;
                &:hover {
                    color: white;
                    background-color: $primary;
                }
            }
        }
    }
    .carousel .control-dots .dot {
        background-color: $primary;
        border:solid 1px white;
        box-shadow: none;
        width: 15px;
        height: 15px;
    }
}