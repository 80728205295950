@import 'src/utils/color.scss';
.header{
    padding: 15px 30px;
    position: fixed;
    top:0;
    z-index: 1000;
    width: calc(100% - 60px);
    background-color: rgba($color: #f5f5f5, $alpha: 0.8);
    nav {
        display: flex;
        gap: 15px;
        justify-content: flex-end;
        position: relative;
        a {
            color:black;
            text-decoration: none;
            &:hover {
                color:$primary;
            }
            i {
                font-size: 2em;
            }
        }
        .translation {
            border:solid 1px $primary;
            cursor: pointer;
            text-transform: capitalize;
            border-radius: 1em;
            text-align: center;
            height:1.55em;
            overflow: hidden;
            transition: all 0.3s ease-in-out;
            font-size: 1.5em;
            li {
                padding: 10px 15px;
                background-color: rgba($color: white, $alpha: 0.3);
                transition: all 0.3s ease-in-out;
                &:hover{
                    background-color: transparent;
                }
            }
            li.active {
                background-color: transparent;
                padding: 5px 15px;
            }
            &:hover {
                height: auto;
                background-color: $primary;
                color: white;
            }
        }
        .menu {
            position: absolute;
            background-color: white;
            top: 70px;
            box-shadow: 5px 5px 20px rgba($color: #000000, $alpha: 0.3);
            text-align: center;
            a {
                display: block;
                padding: 10px 20px;
                transition: all 0.5s ease-in-out;
                color:white;
                background: $primary;
                opacity: 0.7;
                border-bottom: 1px solid white;
                font-size:2em;
                &:hover {
                    opacity: 1;
                }
                &:last-child {
                    border-bottom: 0;
                }
            }
            
        }
    }
}
.collapse {
    transition: all 500ms ease-in-out;
    
    &.open {
        visibility: visible;
        right:0;
    }
    &.close {
        visibility: hidden;
        right:-500px;
        height:0px; 
        padding: 0px;
        margin-top: 0;
    }
}
@media (max-width: 768px) {
    .menu {
        width:100%;
    }
}