@import 'src/utils/color.scss';

#skills {
    background-color: #f5f5f5;
    padding: 50px 0;
}
#skills .skills {
    display: flex;
    gap:30px;
    flex-wrap: wrap;
    justify-content: center;
    .item{
        border:solid 1px $primary;
        padding: 15px 60px;
        transition: all .5s ease-in-out;
        text-align: center;
        flex-grow: 1;
        h3{
            font-weight: 400;
            font-size: 1em;
            padding-top: .5em;
        }
        i {
            font-size: 2em;
        }
        &:hover {
            background-color: $primary;
            color:white;
            .cib-next-js, .cib-typescript {
                color: white;
                filter: invert(1);
            }
        }
        .cib-next-js {
            content: url('../../assets/next-js.svg');
        }
        .cib-typescript {
            content: url('../../assets/typescript.svg');
            max-width: 36px;
            height: auto;
        }
    }
}
@media (max-width:768px) {
    #skills .skills {
        gap:10px;
        .item {
            padding: 15px;
        }
    }
}